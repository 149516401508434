












































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getRegister, smsRegister, mobileChecker } from "../api/login";
import { checkPhone, checkPsw } from "../utils/validate";
import { UserModule } from "@/store/modules/user";
import { Route } from "vue-router";

@Component({
	name: "login"
})
export default class Register extends Vue {
	$refs: { [key: string]: any } = {
		phoneForm: HTMLInputElement,
		passwordForm: HTMLInputElement
	};
	private formData = {
		phone: "",
		password: "",
		// eslint-disable-next-line @typescript-eslint/camelcase
		confirm_password: "",
		code: "",
		agreement: false,
		mobile: ""
	};
	private active = 0;
	private isPsw = true;
	private identityList = [
		{
			isActive: false,
			name: "设备硬件厂商"
		},
		{
			isActive: true,
			name: "渠道商"
		},
		{
			isActive: false,
			name: "方案提供商"
		},
		{
			isActive: false,
			name: "项目需求方"
		}
	];

	private redirect: string | undefined = undefined;
	@Watch("$route", { immediate: true })
	private OnRouteChange(route: Route) {
		// TODO: remove the "as string" hack after v4 release for vue-router
		// See https://github.com/vuejs/vue-router/pull/2050 for details
		this.redirect = route.query && (route.query.redirect as string);
	}

	private onFocus(name: string, active: number) {
		this.$nextTick(() => {
			console.log(this.$refs);
			this.$refs[name].focus();
			this.active = active;
		});
	}

	// 密码格式号格式给出提示
	private checkPassword() {
		const code: any = checkPsw(this.formData.password);
		// 检查密码格式是否验证通过
		if (code !== "验证成功") return this.$message(code);
	}

	private checkActive(index: number) {
		this.identityList.map(n => {
			n.isActive = false;
		});
		this.identityList[index].isActive = true;
	}

	// 检查手机是是否注册
	private mobileChecker() {
		return new Promise<void>((resolve, reject) => {
			mobileChecker(this.formData.phone).then((res: any) => {
				resolve(res);
			});
		});
	}

	private btnText = "获取验证码";
	private countDown = 60;
	// 获取验证码
	private sendCode() {
		if (!this.formData.phone || !checkPhone(this.formData.phone))
			return this.$message.error("请输入并正确手机号!");
		this.btnText = `已发送${this.countDown}s`;
		const timer = setInterval(() => {
			if (this.countDown > 0) {
				this.countDown--;
				this.btnText = `已发送(${this.countDown}S)`;
			} else {
				this.countDown = 60;
				this.btnText = "获取验证码";
				clearInterval(timer);
			}
		}, 1000);
		this.mobileChecker().then((res: any) => {
			if (res.data) {
				this.countDown = 60;
				this.btnText = "获取验证码";
				clearInterval(timer);
				this.$message.error("该手机号已经注册!");
			} else {
				smsRegister({
					mobile: this.formData.phone
				}).then();
			}
		});
	}

	private async register() {
		const { agreement, phone, password } = this.formData;
		if (!agreement) return this.$message.error("请同意服务条款!");
		this.formData.mobile = phone;
		// eslint-disable-next-line @typescript-eslint/camelcase
		this.formData.confirm_password = password;
		const datas = {
			mobile: phone,
			password
		}
		getRegister(this.formData).then(() => {
			UserModule.Login(datas).then((res: any) => {
				console.log(res);
				if (res.code == 0) {
					// this.$router.push({ path: this.redirect || "/" });
					if (res.has_company) {
						this.$router.push({ path: this.redirect || "/" });
					} else {
						this.$router.push({ path: "/userInfo" });
					}
				}
			});
		});
	}
}
