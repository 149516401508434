/*
 * @Author: shikai
 * @Date: 2020-11-18 18:27:21
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-18 19:45:51
 */
// 手机号正则
export const checkPhone = (phone: string) =>
	/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone);

// 密码正则
const reg1 = /[!@#$%^&*()_?<>{}]{1}/;
const reg2 = /([a-zA-Z0-9!@#$%^&*()_?<>{}]){8,18}/;
const reg3 = /[a-zA-Z]+/;
const reg4 = /[0-9]+/;

export const checkPsw = (password: string) => {
	if (
		reg1.test(password) &&
		reg2.test(password) &&
		reg3.test(password) &&
		reg4.test(password)
	) {
		return "验证成功";
	} else if (!reg1.test(password)) {
		return "需包含一个特殊字符";
	} else if (!reg2.test(password)) {
		return "长度在8-18位";
	} else if (!reg3.test(password)) {
		return "需含有字母";
	} else if (!reg4.test(password)) {
		return "需含有数字";
	}
};
